








































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import prettyBytes from "pretty-bytes";
import download from "js-file-download";
import useUser from "@/use/user";
import { joinURL, withQuery } from "ufo";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ["update"],
  setup(props, { emit, root }) {
    const axiosInstance = computed(
      () => root.$store.getters["api/getInstance"] as AxiosInstance
    );

    const baseURL = computed(
      () => root.$store.getters["api/baseURL"] as string
    );

    const { token } = useUser({ root });

    const src = computed(() =>
      withQuery(joinURL(baseURL.value, "attachment", props.id, "thumbnail"), {
        authorization: token.value,
      })
    );

    const previewSrc = computed(() =>
      withQuery(joinURL(baseURL.value, "attachment", props.id, "download"), {
        authorization: token.value,
      })
    );

    const loading = ref(false);
    const disabled = computed(() => !!loading.value);

    const error = ref(false);

    const preview = ref(false);
    const menuList = ref(false);
    const deleteDialog = ref(false);

    watch(preview, () => (error.value = false));

    const details = ref<{
      name: string;
      size: number;
      mimeType: string;
    }>();

    const fetchDetails = async () => {
      loading.value = true;

      await axiosInstance.value
        .get(`attachment/${props.id}/details`)
        .then(({ data }) => {
          details.value = data;
        })
        .catch(() => [])
        .finally(() => {
          loading.value = false;
        });
    };
    watch(() => props.id, fetchDetails, { immediate: true });

    const downloadAttachment = async () => {
      await axiosInstance.value
        .get<ArrayBuffer>(`attachment/${props.id}/download`, {
          responseType: "arraybuffer",
        })
        .then(({ data }) => {
          download(data, details.value?.name ?? "pobrane");
        });
    };

    const deleteAttachment = async () => {
      await axiosInstance.value
        .delete(`attachment/${props.id}`)
        .then(() => [])
        .catch(() => [])
        .finally(() => {
          emit("update");
        });
    };

    const pretty = (size: number) => prettyBytes(size, { locale: "pl" });

    const isImage = computed(() =>
      details.value?.mimeType?.startsWith("image")
    );

    const isVideo = computed(() =>
      details.value?.mimeType?.startsWith("video")
    );

    const isAudio = computed(() =>
      details.value?.mimeType?.startsWith("audio")
    );

    const onError = () => {
      error.value = true;
    };

    return {
      src,
      previewSrc,
      loading,
      disabled,
      error,
      preview,
      menuList,
      deleteDialog,
      details,
      downloadAttachment,
      deleteAttachment,
      pretty,
      isImage,
      isVideo,
      isAudio,
      onError,
    };
  },
});
